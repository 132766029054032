import React from "react"
import { isoStringFromDate } from "../../../ContractApp/lib/dateUtils"
import { addDaysToISODateString, isoDateStringToJSDate } from "@mina-works/datetime-utils"
import { useIntl } from "react-intl"
import plannerPoolService from "../../configuration/PlannerPoolService"
import type DateContext from "../interfaces/DateContext"
import type MobilePool from "./MobilePool"
import type PlannerData from "../../configuration/PlannerData"
import type { PoolEntryWithUsageInfo } from "../interfaces/DateContext"
import type PoolEntry from "../../../commonInterfaces/PoolEntry"

const DATEUPDATEINTERVAL = 1000 // TODO: Increase after test period
const NUMBEROFDAYS = 28

interface Props {
  employeeId?: string
  departmentId?: string
  forceReload?: number
  plannerData?: PlannerData
}

export default function useMobilePool(
  { forceReload, departmentId, plannerData, employeeId }: Props
): MobilePool {
  const intl = useIntl()
  const [availableDates, setAvailableDates] = React.useState<DateContext[]>([])
  const [claimedDates, setClaimedDates] = React.useState<DateContext[]>([])
  const [date, setDate] = React.useState<string>(isoStringFromDate())
  const addClaimedByMeInfo = React.useMemo(
    () => makeAddClaimedByMeInfo(departmentId, employeeId, plannerData),
    [departmentId, employeeId, plannerData]
  )
  React.useEffect(() => {
    const handler = () => setDate(d => {
      const newDate = isoStringFromDate()
      if (newDate !== d) {
        return newDate
      } else {
        return d
      }
    })
    const interval = window.setInterval(handler, DATEUPDATEINTERVAL)
    return () => window.clearInterval(interval)
  }, [])
  React.useEffect(() => {
    const handler = async () => {
      let d = date
      if (d && departmentId) {
        const pool = await plannerPoolService.getPools([departmentId], d, NUMBEROFDAYS)
        const newAvailableDates: DateContext[] = []
        const newClaimedDates: DateContext[] = []
        for (let i = 0; i < NUMBEROFDAYS; i++) {
          const loopDate = d
          const poolDept = pool[departmentId]
          const entriesFromPool = poolDept.reduce((acc, e) => ([...acc, ...(e.entries)]), <PoolEntry[]>[])
          const entries = entriesFromPool?.filter(e => e.date === loopDate) ?? []
          if (entries.length > 0) {
            const entriesWithClaimedInfo = entries.map(addClaimedByMeInfo)
            const availableEntries = entriesWithClaimedInfo.filter(e => e.status === 'available')
            const claimedEntries = entriesWithClaimedInfo.filter(e => e.claimedByMe)
            const common = {
              date: d,
              dateForDisplay: `${isoDateStringToJSDate(d).toLocaleDateString(
                intl.locale,
                {
                  weekday: 'short',
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }
              )} (${i === 0
                ? intl.formatMessage({ id: 'today' })
                : i === 1
                  ? intl.formatMessage({ id: 'tomorrow-' })
                  : intl.formatMessage({ id: 'in-n-days' }, { n: i })})`
            }
            newAvailableDates.push({
              ...common,
              entries: availableEntries
            })
            newClaimedDates.push({
              ...common,
              entries: claimedEntries
            })
          }
          d = addDaysToISODateString(d, 1)
        }
        setAvailableDates(newAvailableDates)
        setClaimedDates(newClaimedDates)
      }
    }
    void handler()
  }, [addClaimedByMeInfo, date, departmentId, forceReload, intl])
  return {
    claimedDates, availableDates,
  }
}

const makeAddClaimedByMeInfo = (
  departmentId?: string,
  employeeId?: string,
  plannerData?: PlannerData
) => (e: PoolEntry): PoolEntryWithUsageInfo => {
  if (
    departmentId === undefined
    || employeeId === undefined
    || plannerData === undefined
  ) {
    return e
  }
  const claimedByMe = !!plannerData
    ?.getPlannerV3()
    ?.employeeCalendarsByOrganisationalUnit[departmentId]
    ?.find(employee => employee.id === employeeId)
    ?.entries
    ?.find(
      entry => entry.startDate === e.date
        && entry.poolReference?.identifier === e.identifier
    )
  // claimedByMe ||= !!plannerData
  //   ?.getPlannerV3()
  //   ?.employeeCalendarsByOrganisationalUnit[departmentId]
  //   ?.find(employee => employee.id === employeeId)
  //   ?.supersededEntries
  //   ?.find(
  //     entry => entry.startDate === e.date
  //       && entry.poolReference?.identifier === e.identifier
  //   )
  return { ...e, claimedByMe }
}