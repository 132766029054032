import type {
  EntryClassificationV3,
  EntryV3,
  LoggedEntryV3,
  OrganisationalUnitV3,
  PlannerV3,
  SimpleTime
} from '../../commonInterfaces/PlannerV3'
import type { Break } from '../configuration/PlannedDay'
import { Entry } from '../configuration/PlannedDay'

/**
 * "Curried" factory
 */
export function makeMakeMobileEntry(plannerData?: PlannerV3): (entry: LoggedEntryV3 | EntryV3) => EditableLoggedEntry {
  return (entry: LoggedEntryV3 | EntryV3) => new EditableLoggedEntry(entry, plannerData)
}

export default class EditableLoggedEntry extends Entry<LoggedEntryV3> {
  constructor(
    entry: LoggedEntryV3 | EntryV3,
    plannerData?: PlannerV3
  ) {
    super(
      {
        ...entry,
        loggedDayIdentifier: (<LoggedEntryV3>entry).loggedDayIdentifier,
        message: (<LoggedEntryV3>entry).message
      },
      plannerData
    )
  }

  static fromEntry(e: Entry): EditableLoggedEntry {
    return new this(e.getEntryV3(), e.getPlannerData())
  }

  public setStartTime(t: string): void {
    this.entry.startTime = simpleTime(t)
  }

  public setEndTime(t: string): void {
    this.entry.endTime = simpleTime(t)
  }

  public isOvernight(): boolean {
    return this.entry.startTime !== undefined
      && this.entry.endTime !== undefined
      && simpleTimeToNumber(this.entry.endTime) < simpleTimeToNumber(this.entry.startTime)
  }

  public setDepartment(o?: OrganisationalUnitV3): void {
    this.entry.organisationalUnitIdForThisEntry = o?.id
  }

  public setClassification(c?: EntryClassificationV3): void {
    this.entry.entryClassification = c
  }

  public clone(): EditableLoggedEntry {
    return new EditableLoggedEntry(
      { ...this.entry /* shallow copy! */ },
      this.plannerData
    )
  }

  public addBreak(brk: Break): void {
    this.entry.breaks?.push(brk.getBreakV3())
  }

  public removeBreak(idx: number): void {
    this.entry.breaks?.splice(idx, 1)
  }

}

export function simpleTimeToNumber(t: SimpleTime): number {
  return t.hour * 60 + t.minute
}

export function simpleTime(t?: string): SimpleTime {
  if (t === undefined) {
    return { hour: 0, minute: 0 }
  }
  const [hs, ms] = t.split(':')
  return {
    hour: parseInt(hs, 10),
    minute: parseInt(ms, 10),
  }
}