/**
 * A simple (but consistent) color bullet to use in
 * dropdowns/selects (for departments, CETs, etc.)
 *
 * Alpha channel is handled by the bullet itself!
 *
 * Can be used as a sort of radio button (optional
 * parameters `onChange` and `active`)
 */

import React from "react"
import type { FullColor, SimpleColor } from "../../commonInterfaces/Color"
import type Color from "../../commonInterfaces/Color"

const DISABLED_ALPHA = 0.05
const ALPHA = 0.4
const ALPHA_OPAQUE = 0.95
const SMALL_DIAMETER = 8
const LARGE_DIAMETER = 14

export interface Props {
  color?: Color
  marginTop?: number
  onChange?: (active: boolean) => void
  active?: boolean
  disabled?: boolean
  large?: boolean
}

/**
 * When using ColorBullet as a button, it is fully controlled – you
 * *must* pass the `active` flag to control whether it considers
 * itself active or not.
 */
export default function ColorBullet(props: Props): JSX.Element {
  const inverted = React.useMemo(
    () => props.onChange !== undefined && !props.disabled && props.active,
    [props.active, props.onChange, props.disabled]
  )
  const { r, g, b } = React.useMemo(() => {
    let rLocal = 255
    let gLocal = 255
    let bLocal = 255
    if (props.color) {
      rLocal =
        (props.color as FullColor).getR !== undefined
          ? (props.color as FullColor).getR()
          : (props.color as SimpleColor).r
      gLocal =
        (props.color as FullColor).getG !== undefined
          ? (props.color as FullColor).getG()
          : (props.color as SimpleColor).g
      bLocal =
        (props.color as FullColor).getB !== undefined
          ? (props.color as FullColor).getB()
          : (props.color as SimpleColor).b
    }
    return { r: rLocal, g: gLocal, b: bLocal }
  }, [props.color])
  const transparentColor = React.useMemo(
    () => `rgba(${r}, ${g}, ${b}, ${props.disabled ? DISABLED_ALPHA : ALPHA})`,
    [b, g, r, props.disabled]
  )
  const almostOpaqueColor = React.useMemo(
    () => `rgba(${r}, ${g}, ${b}, ${ALPHA_OPAQUE})`,
    [b, g, r]
  )
  // const opaqueColor = React.useMemo(() => `rgb(${r}, ${g}, ${b})`, [b, g, r])
  const backgroundColor = React.useMemo(
    () => (inverted ? almostOpaqueColor : transparentColor),
    [inverted, almostOpaqueColor, transparentColor]
  )
  const borderColor = React.useMemo(
    () => (!inverted ? transparentColor : transparentColor),
    [inverted, transparentColor]
  )
  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (props.onChange !== undefined && !props.disabled) {
        e.preventDefault()
        e.stopPropagation()
        props.onChange(!props.active)
      }
      return !props.onChange
    },
    [props]
  )
  const diameter = React.useMemo(
    () => (props.large ? LARGE_DIAMETER : SMALL_DIAMETER),
    [props.large]
  )
  const borderWidth = 2
  const radius = React.useMemo(
    () => diameter - borderWidth,
    [diameter, borderWidth]
  )
  return (
    <div
      onClick={onClick}
      className="color-bullet-container"
      style={{
        display: "inline-block",
        cursor: props.onChange
          ? props.disabled
            ? "not-allowed"
            : "pointer"
          : "auto",
        marginRight: "1em",
        lineHeight: "100%",
        verticalAlign: "middle",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          height: "100%",
          width: "100%",
          left: "0px",
          top: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "1em",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="color-bullet"
            style={{
              backgroundColor: `${backgroundColor}`,
              border: `${borderWidth}px solid ${borderColor}`,
              borderRadius: `${radius}px`,
              width: `${diameter}px`,
              height: `${diameter}px`,
              minWidth: `${diameter}px`,
              minHeight: `${diameter}px`,
              marginTop: `calc(50% - ${diameter}px)`,
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}
