import * as React from "react"
import { ClockCircleOutlined } from "@ant-design/icons"
import { Input, Popover, Radio } from "antd"
import PlannerTime from "../plannerDateTime/PlannerTime"
import "./timeWidget.css"
import type { SizeType } from "antd/lib/config-provider/SizeContext"

export interface TimeWidgetProps {
  showMinutes?: boolean
  minuteStepSize?: number
  value?: PlannerTime
  size?: "small" | "middle" | "large"
  onChange: (value: PlannerTime) => void
  allowUndefined?: boolean
  required?: boolean
  disabled?: boolean
  focus?: boolean
}

export default function TimeWidget(props: TimeWidgetProps): JSX.Element {
  const inputFieldRef: React.RefObject<any> = React.createRef()
  const popoverRef: React.RefObject<any> = React.createRef()
  const [dropdownOpened, setDropdownOpened] = React.useState(false)
  const [textValue, setTextValue] = React.useState("")
  const [textIsValid, setTextIsValid] = React.useState(false)
  const showMinutes = React.useMemo(() => {
    return props.showMinutes !== false
  }, [props.showMinutes])
  // React.useEffect(() => {
  //   if (props.focus) {
  //     inputFieldRef.current.focus()
  //     inputFieldRef.current.select()
  //   }
  // }, [props.onChange, inputFieldRef, props.focus])
  const buildText = React.useCallback(
    (h: number, m = 0) => {
      const hString = `${h}`.padStart(2, "0")
      if (showMinutes) {
        return `${hString}:${`${m}`.padStart(2, "0")}`
      }
      return hString
    },
    [showMinutes]
  )
  const buildTextFromText = React.useCallback(
    (text: string): string => {
      if (!text) {
        return ""
      }
      const hm = getRawHMFromText(text)
      if (hm) {
        return buildText(hm.h, hm.m)
      }
      return ""
    },
    [buildText]
  )
  const minuteStepSize = React.useMemo(() => {
    return props.minuteStepSize ?? 1 // 5
  }, [props.minuteStepSize])
  React.useEffect(() => {
    setTextValue(
      buildTextFromText(
        props.value?.toString() ?? (props.allowUndefined ? "" : "00:00")
      )
    )
  }, [buildTextFromText, props.allowUndefined, props.value])
  const [opacity, setOpacity] = React.useState<number>(0)
  const handleOpenDropdown = React.useCallback((open: boolean) => {
    setDropdownOpened(open)
    if (open) {
      setTimeout(() => setOpacity(100), 150)
    } else {
      setOpacity(0)
    }
  }, [])
  const getHour = React.useCallback(
    (defaultValue: number = 0) => {
      const v = validateAndPrepText(
        showMinutes,
        minuteStepSize,
        buildText,
        textValue
      )
      if (v !== null) {
        return v ? v.h : 0
      }
      return defaultValue
    },
    [buildText, minuteStepSize, showMinutes, textValue]
  )
  const getMinute = React.useCallback(
    (defaultValue: number = 0) => {
      const v = validateAndPrepText(
        showMinutes,
        minuteStepSize,
        buildText,
        textValue
      )
      if (v !== null) {
        return v ? v.m : 0
      }
      return defaultValue
    },
    [buildText, minuteStepSize, showMinutes, textValue]
  )
  const buildTime = React.useCallback(
    (hour?: number, minute?: number) => {
      hour = hour ?? getHour(0)
      minute = minute ?? getMinute(0)
      if (hour === 24) {
        minute = 0
      }
      return new PlannerTime(hour!, minute!)
    },
    [getHour, getMinute]
  )
  const triggerOnChange = React.useCallback(
    (time: PlannerTime) => {
      props.onChange(time)
    },
    [props]
  )
  const buildTimeAndTriggerOnChange = React.useCallback(
    (hour?: number, minute?: number) => {
      const time = buildTime(hour, minute)
      triggerOnChange(time)
    },
    [buildTime, triggerOnChange]
  )
  const prevPropsValue = React.useRef<PlannerTime | undefined>(props.value)
  React.useEffect(() => {
    const prevValue = prevPropsValue.current
    prevPropsValue.current = props.value
    if (
      // init for sloppy user code
      !prevValue &&
      !props.value &&
      !textValue &&
      !props.allowUndefined
    ) {
      setTextValue(
        props.allowUndefined ? "" : showMinutes ? "00:00" : "00" // TODO: verify, and make single digits possible
      )
    } else if (
      // avoids redundancies
      (!prevValue && !textValue && props.value) ||
      (prevValue &&
        buildTextFromText(textValue ?? "") !==
          buildTextFromText(props.value?.toString() ?? "") &&
        !prevValue.equals(props.value))
    ) {
      setTextValue(buildTextFromText(props.value?.toString() ?? ""))
    }
    const handlePopoverEscape = (e: React.KeyboardEvent) => {
      if (e.keyCode === /* ESC */ 27) {
        setDropdownOpened(false)
        inputFieldRef.current.focus()
      }
    }
    const current = popoverRef.current
    current?.addEventListener("keydown", handlePopoverEscape)
    return () => current?.removeEventListener("keydown", handlePopoverEscape)
  }, [
    buildTextFromText,
    dropdownOpened,
    inputFieldRef,
    popoverRef,
    props.allowUndefined,
    props.value,
    showMinutes,
    textValue,
  ])
  const setNewValue = React.useCallback(
    (v: { text: string; h: number; m: number } | null, currentText: string) => {
      if (v) {
        setTextIsValid(true)
        setTextValue(v.text)
        buildTimeAndTriggerOnChange(v.h, v.m)
      } else {
        setTextIsValid(false)
        setTextValue(currentText)
      }
    },
    [buildTimeAndTriggerOnChange]
  )
  const handleTextChanged = React.useCallback(
    (e: any) => {
      const currentText: string = e.target.value
      const v = validateAndPrepText(
        showMinutes,
        minuteStepSize,
        buildText,
        currentText,
        {
          fix: false,
          onlyAcceptCompleteTime: true,
        }
      )
      setNewValue(v, currentText)
    },
    [buildText, minuteStepSize, setNewValue, showMinutes]
  )
  const handleTextEntered = React.useCallback(
    (e: any) => {
      const currentText: string = e.target.value
      const v = validateAndPrepText(
        showMinutes,
        minuteStepSize,
        buildText,
        currentText,
        {
          fix: true,
          onlyAcceptCompleteTime: false,
        }
      )
      setNewValue(v, currentText)
    },
    [buildText, minuteStepSize, setNewValue, showMinutes]
  )
  const handleHourClick = React.useCallback(
    (h: number) => {
      const m = getMinute(0)
      setTextIsValid(true)
      setTextValue(buildText(h, m))
      buildTimeAndTriggerOnChange(h, m)
    },
    [buildText, buildTimeAndTriggerOnChange, getMinute]
  )
  const handleMinuteClick = React.useCallback(
    (m: number) => {
      const h = getHour(0)
      setTextValue(buildText(h ?? 0, m))
      setTextIsValid(true)
      buildTimeAndTriggerOnChange(h, m)
    },
    [buildText, buildTimeAndTriggerOnChange, getHour]
  )
  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === /* ESC */ 27) {
      setDropdownOpened(false)
    }
  }, [])
  const value = React.useMemo(
    () => (props.allowUndefined || props.value ? textValue ?? "" : ""),
    [props.allowUndefined, props.value, textValue]
  )
  const cls = ["cal-time-widget"]
  if (false === textIsValid) {
    cls.push("invalid-time")
  }
  if (!showMinutes) {
    cls.push("hours-only")
  }
  return (
    <div className={cls.join(" ")}>
      <Input
        autoFocus={props.focus}
        required={props.required}
        disabled={props.disabled}
        ref={inputFieldRef}
        type="text"
        onChange={handleTextChanged}
        onKeyDown={onKeyDown}
        onBlur={handleTextEntered}
        size={props.size}
        value={value}
        suffix={
          <Popover
            style={{ opacity, overscrollBehavior: "contain" }}
            getPopupContainer={node =>
              node.parentNode?.parentElement ?? document.body
            }
            overlayInnerStyle={{ opacity }}
            overlayStyle={{ opacity }}
            arrow={{ pointAtCenter: true }}
            open={dropdownOpened}
            onOpenChange={handleOpenDropdown}
            trigger={["click"]}
            autoAdjustOverflow={false}
            content={
              <div
                className="time-widget-popover-container"
                tabIndex={-1}
                style={{
                  opacity,
                  overscrollBehavior: "contain",
                  width: "100%",
                  height: "100%",
                }}
                ref={popoverRef}
              >
                <div className="time-widget-list">
                  <HoursList
                    isOpen={dropdownOpened}
                    hour={getHour()}
                    handleHourClick={handleHourClick}
                    setDropdownOpened={setDropdownOpened}
                    size={props.size}
                  ></HoursList>
                </div>
                {showMinutes ? (
                  <div className="time-widget-list">
                    <MinutesList
                      isOpen={dropdownOpened}
                      hour={getHour()}
                      minute={getMinute()}
                      minuteStepSize={minuteStepSize}
                      handleMinuteClick={handleMinuteClick}
                      setDropdownOpened={setDropdownOpened}
                      size={props.size}
                    ></MinutesList>
                  </div>
                ) : null}
              </div>
            }
            overlayClassName="cal-time-widget-popover"
            placement="bottomRight"
          >
            <ClockCircleOutlined></ClockCircleOutlined>
          </Popover>
        }
      ></Input>
    </div>
  )
}

function HoursList({
  hour,
  handleHourClick,
  setDropdownOpened,
  size,
  isOpen,
}: {
  setDropdownOpened: (open: boolean) => void
  size: SizeType
  hour: number
  handleHourClick: (m: number) => void
  isOpen: boolean
}): JSX.Element {
  const data = new Array(25).fill(1).map((_, idx) => idx)
  const value = hour
  return (
    <TimeComponentList
      onChange={handleHourClick}
      values={data}
      currentValue={value ?? 0}
      size={size}
      isOpen={isOpen}
      setDropdownOpened={setDropdownOpened}
    ></TimeComponentList>
  )
}

function MinutesList({
  hour,
  minute,
  minuteStepSize,
  handleMinuteClick,
  setDropdownOpened,
  size,
  isOpen,
}: {
  setDropdownOpened: (open: boolean) => void
  size: SizeType
  handleMinuteClick: (m: number) => void
  hour: number
  minute: number
  minuteStepSize: number
  isOpen: boolean
}): JSX.Element {
  // TODO: stepsize option
  const data =
    hour === 24
      ? [0]
      : new Array(60 / minuteStepSize)
          .fill(1)
          .map((_, idx) => idx * minuteStepSize)
  const value = minute
  return (
    <TimeComponentList
      onChange={handleMinuteClick}
      values={data}
      currentValue={value ?? 0}
      size={size}
      isOpen={isOpen}
      setDropdownOpened={setDropdownOpened}
    ></TimeComponentList>
  )
}

function TimeComponentList({
  onChange,
  values,
  currentValue,
  setDropdownOpened,
  size,
  isOpen,
}: {
  isOpen: boolean
  onChange: any
  values: number[]
  currentValue: number
  setDropdownOpened: (open: boolean) => void
  size: SizeType
  // TODO: highlighted etc.
}): JSX.Element {
  const ref = React.createRef<HTMLDivElement>()
  const currentValueRef = React.useRef<number>()
  const currentOpenRef = React.useRef<boolean>()
  React.useEffect(() => {
    const curRef = ref.current
    const prevVal = currentValueRef.current
    const prevOpen = currentOpenRef.current
    currentValueRef.current = currentValue
    currentOpenRef.current = isOpen
    if (prevVal !== currentValue || (isOpen && !prevOpen)) {
      setTimeout(() => curRef?.scrollIntoView(), 250)
    }
  }, [currentValue, isOpen, ref])
  const onChangeDispatch = React.useCallback(
    (e: any) => onChange(e.target.value),
    [onChange]
  )
  const onDblClickDispatch = React.useCallback(
    (e: any) => {
      const v = safeParseInt((e.target as HTMLSpanElement).innerText)
      setDropdownOpened(false)
      onChange(v)
    },
    [onChange, setDropdownOpened]
  )
  return (
    <div onDoubleClick={onDblClickDispatch}>
      <Radio.Group
        onChange={onChangeDispatch}
        value={`${currentValue}`}
        size={size}
      >
        {values.map(v => {
          const cls = []
          const selected = currentValue === v
          if (selected) {
            cls.push("selected-time")
          }
          const r = (
            <React.Fragment key={v}>
              {selected ? <div key="selected-ref2" ref={ref}></div> : null}
              <Radio
                autoFocus={selected}
                className={cls.join(" ")}
                value={v}
                key={v}
                style={{
                  display: "block",
                  border: "none",
                }}
              >
                {`${v}`.padStart(2, "0")}
              </Radio>
            </React.Fragment>
          )
          return r
        })}
      </Radio.Group>
    </div>
  )
}

// TODO: The logic for this should be separated out, and tested!
function validateAndPrepText(
  showMinutes: boolean,
  minuteStepSize: number,
  buildText: (h: number, m: number) => string,
  text?: string,
  {
    fix = true,
    onlyAcceptCompleteTime = true,
  }: {
    fix?: boolean // fix incomplete values (blur)
    onlyAcceptCompleteTime?: boolean // only accept a full value (keyUp)
  } = {}
): {
  text: string
  h: number
  m: number
} | null {
  let result = null // this.props.allowUndefined ? { text: "", h: 0, m: 0 } : null
  text = text?.trim() ?? ""
  const textIsWellFormed_ = textIsWellFormed(
    text,
    showMinutes,
    onlyAcceptCompleteTime
  )
  if (textIsWellFormed_) {
    const hourAndMinute = getRawHMFromText(text)
    if (
      verifyCompleteness(onlyAcceptCompleteTime, hourAndMinute.h, text) &&
      verifyAndFixHourAndMinuteCorrectness(hourAndMinute, minuteStepSize, fix)
    ) {
      result = formatFinalValidationResult(hourAndMinute, buildText)
    }
  }
  return result
}

function textIsWellFormed(
  text: string,
  showMinutes: boolean,
  onlyAcceptCompleteTime = false
) {
  if (showMinutes) {
    return onlyAcceptCompleteTime
      ? !!text.match(/^(?:\d+[:]\d\d)|(?:\d\d\d\d)$/)
      : !!text.match(/^\d+(?:[:?]\d*)?$/)
  } else {
    return !!text.match(/^\d+$/)
  }
}

function verifyAndFixHourAndMinuteCorrectness(
  hourAndMinute: HourAndMinute,
  minuteStepSize: number,
  fix: boolean
) {
  // WARNING: The order below must be preserved, since the
  //          FIXES are incremental!
  return (
    verifyAndFixMinuteCorrectness(hourAndMinute, minuteStepSize, fix) &&
    verifyAndFixHourCorrectness(hourAndMinute, fix) &&
    verifyAndFixMidnightCorrectness(hourAndMinute, fix)
  )
}

// Modifies value in place!
function verifyAndFixMinuteCorrectness(
  hourAndMinute: HourAndMinute,
  minuteStepSize: number,
  fix: boolean
): boolean {
  let result = true
  applyStepSize(hourAndMinute, minuteStepSize)
  if (hourAndMinute.m < 0 || hourAndMinute.m > 59) {
    if (fix && hourAndMinute.m < 0) {
      // fix incorrect minutes
      hourAndMinute.m = 0
    } else if (fix && hourAndMinute.m > 59) {
      hourAndMinute.h += Math.floor(hourAndMinute.m / 60)
      hourAndMinute.m %= 60
    } else {
      result = false
    }
  }
  return result
}

// Modifies value in place!
// MUST follow minute corrections!
function verifyAndFixHourCorrectness(
  hourAndMinute: HourAndMinute,
  fix: boolean
): boolean {
  let result = true
  if (hourAndMinute.h < 0 || hourAndMinute.h > 24) {
    if (fix) {
      hourAndMinute.h = hourAndMinute.h < 0 ? 0 : 24
    } else {
      result = false
    }
  }
  return result
}

// Modifies value in place!
// Special case for 24:00 (no minutes allowed)
// MUST follow minute AND hour corrections!
function verifyAndFixMidnightCorrectness(
  hourAndMinute: HourAndMinute,
  fix: boolean
): boolean {
  let result = true
  if (hourAndMinute.h === 24 && hourAndMinute.m !== 0) {
    if (fix) {
      hourAndMinute.m = 0
    } else {
      result = false
    }
  }
  return result
}

function applyStepSize(hourAndMinute: { m: number }, minuteStepSize: number) {
  hourAndMinute.m =
    Math.round(hourAndMinute.m / minuteStepSize) * minuteStepSize
}

function formatFinalValidationResult(
  hourAndMinute: HourAndMinute,
  buildText: (h: number, m: number) => string
) {
  const { h, m } = hourAndMinute
  return {
    text: buildText(h, m),
    h,
    m,
  }
}

function getRawHMFromText(text: string): HourAndMinute {
  let h = 0
  let m = 0
  if (text.includes(":")) {
    ;[h, m] = text.split(":").map(el => safeParseInt(el ?? "0"))
  } else if (/^\d\d?$/.exec(text)) {
    const hString = text.slice(0, 2)
    if (hString) {
      h = safeParseInt(hString)
    }
  } else if (/^\d\d\d$/.exec(text)) {
    // XXX SIMPLIFICATION: Assume full minutes
    ;[h, m] = getHMFromFourDigits(text)
  } else if (/^\d\d\d\d$/.exec(text)) {
    const hString = text.slice(0, 2)
    const mString = text.slice(2, 4)
    if (hString && mString) {
      h = safeParseInt(hString)
      m = safeParseInt(mString)
    }
  }
  if (m === undefined || m === null) {
    m = 0
  } // might be undefined (no colon)
  return { h, m }
}

/**
 * Note: This defaults to 0 for things that are not ints
 */
function safeParseInt(s: string): number {
  const trimmed = s.trim()
  return trimmed.match(/^[0-9]+$/) ? parseInt(trimmed, 10) : 0
}

function getHMFromFourDigits(text: string) {
  return (
    getHMFromFourDigitsAssumeFullMinutes(text) ??
    getHMFromFourDigitsAssumeSingleDigitMinute(text)
  )
}

function getHMFromFourDigitsAssumeFullMinutes(text: string): number[] | null {
  let h = 0
  let m = 0
  const hString: string = text.slice(0, 1)
  const mString: string = text.slice(1, 3)
  if (hString && mString) {
    h = safeParseInt(hString)
    m = safeParseInt(mString)
    if (h <= 24 && m < 60) {
      return [h, m]
    }
  }
  return null
}

function getHMFromFourDigitsAssumeSingleDigitMinute(text: string) {
  let h = 0
  let m = 0
  const hString: string = text.slice(0, 2)
  const mString: string = text.slice(2, 3)
  if (hString && mString) {
    h = safeParseInt(hString)
    m = safeParseInt(mString)
  }
  return [h, m]
}

function verifyCompleteness(
  onlyAcceptCompleteTime: boolean,
  h: number,
  text: string
) {
  return !onlyAcceptCompleteTime || !isIncompleteTime(h, text)
}

function isIncompleteTime(h: number, text: string) {
  return h < 3 && !text.startsWith("0")
}

interface HourAndMinute {
  h: number
  m: number
}
