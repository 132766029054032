import * as React from "react"
import type DateContext from "./interfaces/DateContext"
import { Button } from "antd"
import type { Break } from "../../commonInterfaces/PoolEntry"
import { useIntl } from "react-intl"
import type PlannerData from "../configuration/PlannerData"
import useMobilePoolCallbacks from "./hooks/useMobilePoolCallbacks"
import { timeToString } from "@mina-works/planner-utils"
import type { PoolEntryWithUsageInfo } from "./interfaces/DateContext"
import useMobilePoolReturnCallbacks from "./hooks/useMobilePoolReturnCallbacks"
import ColorBullet from "../../shared/ColorBullet/ColorBullet"

interface Props {
  dates: DateContext[]
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
}

export default function MobilePoolList({
  departmentId,
  employeeId,
  dates,
  plannerData,
  triggerReload,
}: Props): JSX.Element {
  return (
    <div className="mobile-pool-list">
      {dates.map(d => (
        <MobilePoolListDate
          triggerReload={triggerReload}
          key={d.date}
          plannerData={plannerData}
          departmentId={departmentId}
          employeeId={employeeId}
          dateContext={d}
        ></MobilePoolListDate>
      ))}
      <div className="mobile-pool-list-padding-for-phone">&nbsp;</div>
    </div>
  )
}

function MobilePoolListDate({
  dateContext,
  departmentId,
  employeeId,
  plannerData,
  triggerReload,
}: {
  dateContext: DateContext
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
}): JSX.Element {
  const intl = useIntl()
  const entries = React.useMemo(
    () => dateContext.entries,
    [dateContext.entries]
  )
  const canBeReturned = React.useMemo(
    () => !!entries.find(e => e.claimedByMe),
    [entries]
  )
  const { returnToPool } = useMobilePoolReturnCallbacks({
    employeeId,
    departmentId,
    plannerData,
    date: dateContext.date,
    triggerReload,
  })
  return (
    <div className="mobile-pool-list-date">
      <div className="mobile-pool-date-h">
        {dateContext.dateForDisplay}
        {canBeReturned ? (
          <Button
            className="mobile-pool-return-btn"
            type="link"
            onClick={returnToPool}
          >
            {intl.formatMessage({ id: "return-to-pool" })}
          </Button>
        ) : null}
      </div>
      <div className="mobile-pool-date-list">
        {entries.length === 0
          ? intl.formatMessage({ id: "noentries" })
          : entries.map((e, idx) => (
              <MobilePoolListEntry
                triggerReload={triggerReload}
                key={`${idx}-${e.identifier}`}
                plannerData={plannerData}
                departmentId={departmentId}
                employeeId={employeeId}
                entry={e}
              ></MobilePoolListEntry>
            ))}
      </div>
    </div>
  )
}

function MobilePoolListEntry({
  entry,
  departmentId,
  employeeId,
  plannerData,
  triggerReload,
}: {
  entry: PoolEntryWithUsageInfo
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
}): JSX.Element | null {
  const intl = useIntl()
  const { save } = useMobilePoolCallbacks({
    selectedEntry: entry,
    departmentId,
    employeeId,
    plannerData,
    triggerReload,
  })
  const supersedingClassification = React.useMemo(
    () => entry?.origin?.supersededBy,
    [entry]
  )
  const isHomeSick = React.useMemo(
    () => supersedingClassification?.unavailabilitytype === "sick",
    [supersedingClassification]
  )
  const isOnVaca = React.useMemo(
    () =>
      supersedingClassification?.unavailabilitytype === "vacation" ||
      supersedingClassification?.unavailabilitytype === "special",
    [supersedingClassification]
  )
  const isSuperseded = !!entry?.origin?.supersededBy
  const saveCallback = React.useCallback(() => {
    if (
      entry.startTime &&
      entry.endTime &&
      window.confirm(
        intl.formatMessage(
          { id: "pool-mobile-save-confirm" },
          {
            start: timeToString(entry.startTime),
            end: timeToString(entry.endTime),
          }
        )
      )
    ) {
      save()
    }
  }, [intl, entry.startTime, entry.endTime, save])
  const className = React.useMemo(
    () =>
      `mobile-pool-list-entry${
        entry.claimedByMe ? " mobile-pool-list-entry-claimed-by-me" : ""
      }`,
    [entry.claimedByMe]
  )
  return entry.breaks !== undefined &&
    entry.startTime !== undefined &&
    entry.endTime !== undefined ? (
    <div className={`${className}`}>
      <div className="mple-color-bullet">
        {entry.calendarEntryType?.color ? (
          <ColorBullet color={entry.calendarEntryType?.color}></ColorBullet>
        ) : null}
      </div>
      <div className="mple-inner">
        <div className="mple-inner-cet">{entry.calendarEntryType?.name}</div>
        {entry.claimedByMe ? (
          <div className="mple-addon">
            {intl.formatMessage({ id: "claimed" })}
          </div>
        ) : null}
        <div className="mple-inner-time">
          <TimeView time={entry.startTime}></TimeView>-
          <TimeView time={entry.endTime}></TimeView>
        </div>
        <div className="mple-pool-breaks">
          {entry.breaks.map((b, idx) => (
            <MobilePoolListEntryBreak
              brk={b}
              key={`${idx}`}
            ></MobilePoolListEntryBreak>
          ))}
        </div>
        {entry.comment ? (
          <div className="mobile-pool-comment">{entry.comment}</div>
        ) : null}
        {isSuperseded ? (
          <div className="mobile-pool-origin-superseding-cet">
            {isHomeSick
              ? intl.formatMessage({
                  id: "pool-entry-superseded-employee-is-home-sick",
                })
              : isOnVaca
              ? intl.formatMessage({
                  id: "pool-entry-superseded-employee-is-on-vacation",
                })
              : intl.formatMessage({
                  id: "pool-entry-superseded-employee-is-unavailable",
                })}
          </div>
        ) : null}
        {entry.origin?.employee ? (
          <div className="mobile-pool-origin-info-employee">
            {intl.formatMessage(
              { id: "pool-entry-info-based-on-employee" },
              { name: entry.origin.employee.name }
            )}
          </div>
        ) : null}
      </div>
      {entry.claimedByMe ? null : (
        <div className="mobile-pool-list-entry-cb">
          <Button
            className="mple-inner-btn"
            type="primary"
            disabled={entry.status !== "available"}
            onClick={saveCallback}
          >
            {intl.formatMessage({ id: "pool-mobile-save-btn" })}
          </Button>
        </div>
      )}
    </div>
  ) : null
}

function MobilePoolListEntryBreak({ brk }: { brk: Break }): JSX.Element {
  const intl = useIntl()
  return (
    <div className="mobile-pool-list-entry-break">
      <TimeView time={brk.startTime}></TimeView>-
      <TimeView time={brk.endTime}></TimeView> {brk.durationInMinutes}min{" "}
      {intl.formatMessage({ id: "break" })}
    </div>
  )
}

function TimeView({
  time: { hour, minute },
}: {
  time: {
    hour: number
    minute: number
  }
}): JSX.Element {
  const h = React.useMemo(() => `${hour}`.padStart(2, "0"), [hour])
  const m = React.useMemo(() => `${minute}`.padStart(2, "0"), [minute])
  return (
    <div className="mobile-pool-time">
      {h}:{m}
    </div>
  )
}
