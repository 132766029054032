/**
 * WARNING: This hook should only be used once if possible!
 * (It is NOT currently used this way!)
 *
 * Alternatively, it might be converted to a context manager for convenience.
 */
import React from "react"
import PlannerData from "../configuration/PlannerData"
import { useIntl } from "react-intl"
import useAsyncServiceWorker from "./useAsyncServiceWorker"

const DEFAULTNUMDAYS = 28

interface Props {
  clientId?: string
  employeeId?: string
  date?: Date
  numberOfDays?: number
  forceReload: number
}

interface UseLoadPlanner {
  plannerData?: PlannerData
}

export default function useLoadPlanner({
  clientId, employeeId,
  date, numberOfDays,
  forceReload
}: Props): UseLoadPlanner {
  const intl = useIntl()
  const [plannerData, setPlannerData] = React.useState<PlannerData | undefined>()
  const employeeIdRef = React.useRef<string | undefined>()
  const forceReloadRef = React.useRef<number>()
  const unconditionallyReload = React.useCallback(async () => {
    if (clientId && employeeId) { // These are just practical conditions – necessary
      const startDate = date ?? new Date()
      const newPlannerData = new PlannerData(clientId, employeeId, intl.locale, {
        startDate,
        endDate: new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + (numberOfDays ?? DEFAULTNUMDAYS)
        )
      })
      await newPlannerData.load().then(() => setPlannerData(newPlannerData))
    }
  }, [clientId, date, employeeId, intl.locale, numberOfDays])
  useAsyncServiceWorker(unconditionallyReload)
  React.useEffect(() => {
    const prevEmployeeId = employeeIdRef.current
    const prevForceReload = forceReloadRef.current
    employeeIdRef.current = employeeId
    forceReloadRef.current = forceReload
    if (clientId && employeeId && (prevEmployeeId !== employeeId || prevForceReload !== forceReload)) {
      void unconditionallyReload()
    }
  }, [clientId, date, employeeId, intl.locale, numberOfDays, forceReload, unconditionallyReload])
  return { plannerData, }
}