import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import * as React from "react"
import useMobilePool from "./hooks/useMobilePool"
import MobilePoolList from "./MobilePoolList"
import useLoadPlanner from "../hooks/useLoadPlanner"
import { useIntl } from "react-intl"
import { Tabs } from "antd"

interface Props {
  availableFeatures: FeatureType[]
  clientId?: string
  departmentId?: string
  employeeId?: string
}

export default function MobilePool({
  departmentId,
  employeeId,
  clientId,
}: Props): JSX.Element {
  const intl = useIntl()
  const [forceReload, setForceReload] = React.useState<number>(Date.now())
  const { plannerData } = useLoadPlanner({ employeeId, clientId, forceReload })
  const { availableDates, claimedDates } = useMobilePool({
    plannerData,
    employeeId,
    departmentId,
    forceReload,
  })
  const triggerReload = React.useCallback(() => setForceReload(Date.now()), [])
  return (
    <div className="mobile-pool-app">
      <h1>{intl.formatMessage({ id: "pool" })}</h1>
      {plannerData !== undefined &&
      departmentId !== undefined &&
      clientId !== undefined &&
      employeeId !== undefined ? (
        <Tabs
          items={[
            {
              label: intl.formatMessage({ id: "available-pool-entries" }),
              key: "avail",
              children: (
                <MobilePoolList
                  triggerReload={triggerReload}
                  dates={availableDates}
                  plannerData={plannerData}
                  departmentId={departmentId}
                  employeeId={employeeId}
                ></MobilePoolList>
              ),
            },
            {
              label: intl.formatMessage({ id: "claimed-pool-entries" }),
              key: "claimed",
              children: (
                <MobilePoolList
                  triggerReload={triggerReload}
                  dates={claimedDates}
                  plannerData={plannerData}
                  departmentId={departmentId}
                  employeeId={employeeId}
                ></MobilePoolList>
              ),
            },
          ]}
        ></Tabs>
      ) : null}
    </div>
  )
}
